<template>
  <el-container style="height: 100%">
    <el-header>
      <p>
        <span style="margin-right: 15px">员工打卡管理系统</span>
        <i
          :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
          @click="isCollapse = !isCollapse"
        ></i>
      </p>
      <el-button type="primary" size="small" @click="OutLogin"
        >退出登录</el-button
      >
    </el-header>

    <el-container style="height: 100%">
      <el-aside :width="isCollapse ? '' : '200px'">
        <el-menu
          :default-active="defaultPath"
          class="el-menu-vertical-demo"
          :collapse="isCollapse"
          :collapse-transition="false"
          :unique-opened="true"
          router
        >
          <el-submenu :index="item.name" v-for="item in menus" :key="item.id">
            <template slot="title">
              <i :class="item.icon"></i>
              <span>{{ item.name }}</span>
            </template>
            <el-menu-item-group v-for="ele in item.child" :key="ele.id">
              <el-menu-item :index="ele.path">
                <i :class="ele.icon"></i>
                <span>{{ ele.name }}</span></el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-aside>

      <el-main style="height: 685px; background-color: #f3f4f6">
        <el-row class="bread" v-if="$route.path!='/'">
          <el-breadcrumb separator-icon="ArrowRight">
            <el-breadcrumb-item
              :to="item.path"
              v-for="(item, index) in $route.matched"
              :key="index"
              >{{item.meta.title }}</el-breadcrumb-item
            >
          </el-breadcrumb>
        </el-row>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      isCollapse: false,
      defaultPath: "staff/userList",
      menus: [
        {
          id: 1,
          name: "员工管理",
          icon: "el-icon-user",
          path: "null",
          child: [
            {
              pid: 1,
              id: 10,
              name: "员工列表",
              icon: "el-icon-tickets",
              path: "/staff/userList",
            },
          ],
        },
        {
          id: 2,
          name: "打卡管理",
          icon: "el-icon-medal",
          path: "null",
          child: [
            {
              pid: 2,
              id: 11,
              name: "打卡列表",
              icon: "el-icon-tickets",
              path: "/daka/clockList",
            },
          ],
        },
        {
          id: 3,
          name: "设备管理",
          icon: "el-icon-monitor",
          path: "null",
          child: [
            {
              pid: 3,
              id: 12,
              name: "设备列表",
              icon: "el-icon-tickets",
              path: "/device/deviceList",
            },
          ],
        },
        {
          id: 4,
          name: "账号设置",
          icon: "el-icon-setting",
          path: "null",
          child: [
            {
              pid: 4,
              id: 5,
              name: "账号管理",
              icon: "el-icon-tickets",
              path: "/account/accountList",
            },
            {
              pid: 4,
              id: 6,
              name: "角色管理",
              icon: "el-icon-connection",
              path: "/account/access",
            },
            // {
            //   pid: 4,
            //   id: 7,
            //   name: "权限管理",
            //   icon: "el-icon-connection",
            //   path: "/account/rulesList",
            // },
          ],
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.defaultPath = this.$route.path;
  },
  methods: {
    //退出登录
    OutLogin() {
      this.$confirm("真的要退出吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store.dispatch("OutLogin");
          this.$router.push("/login");
          this.$message({
            type: "success",
            message: "退出成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
    },
  },
};
</script>
<style scoped lang="scss">
.el-header {
  width: 100%;
  height: 50px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1e40af;
  color: #fff;
}
.el-aside {
  height: 100% !important;
}
.bread{
  background-color: #fff;
  display: flex;
  align-items: center;
  padding-left: 20px;
  box-sizing: border-box;
}
::v-deep .el-menu {
  height: 100% !important;
}

:deep(.el-tag--small) {
  margin-right: 5px;
}

::v-deep .el-menu-item-group__title{
    padding: 0 !important;
}
</style>
