import axios from "axios";

const serve = axios.create({
  baseURL: "https://hya.zvwvz.cn",
  //   baseURL: "/api",
    timeout: "",
    // header:{"token":localStorage.getItem("token")}
});

serve.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    let token=localStorage.getItem("token")
    if(token){
      config.headers.token=token
    }
    /*if (config.method == "post" && token !=="" ) {
      config.data.token = localStorage.getItem("token");
    }*/
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

serve.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    // 解决token错误或是过期
    // 把response的data返回给客户端, 不需要可以删除下面1句代码
    return response.data;
  },
  function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default serve;
