import http from "./requery";


//获取code
export const GetCode = () => http.post("/getCode");

//登录
export const Login = (data) => http.post("/user/login", data);

//员工
// 列表
export const GetList = (data) => http.post("/staff/index", data);

//详情
export const GetDetail = (data) => http.post("/authorityCenterDetail", data);

//添加员工
export const AddList = (data) => http.post("/staff/add", data);

//编辑员工
export const EditList = (data) => http.post("/staff/edit", data);

//删除员工
export const DelList = (data) => http.post("/staff/del", data);

//状态
export const changeStatu = (data) => http.post("/updateAuthorityCenter", data);

// 打卡
// 列表
export const GetClockList = (data) => http.post("/Clock/index", data);

//删除
export const ClockDel = (data) => http.post("/Clock/del", data);

//角色

// 设备
// 列表
export const GetDeviceList = (data) => http.post("/device/index", data);
// 删除
export const DeviceDel = (data) => http.post("/device/del", data);
// 添加
export const DeviceDdd = (data) => http.post("/device/add", data);
// 编辑
export const DeviceEdit = (data) => http.post("/device/edit", data);

// 账号管理
export const GetAccountList = (data) => http.post("/account/index", data);
// 删除
export const AccountDel = (data) => http.post("/account/del", data);
// 添加
export const AccountDdd = (data) => http.post("/account/add", data);
// 编辑
export const AccountEdit = (data) => http.post("/account/edit", data);

// 角色管理
export const GetRoleList = (data) => http.post("/role/index", data);
// 删除
export const RoleDel = (data) => http.post("/role/del", data);
// 添加
export const RoleDdd = (data) => http.post("/role/add", data);
// 编辑
export const RoleEdit = (data) => http.post("/role/edit", data);
// 权限查询
export const RoleAuth = (data) => http.post("/role/auth_index", data);
// 权限提交
export const RoleAuthEdit = (data) => http.post("/role/auth_edit", data);