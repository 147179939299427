import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "staff/userList", // 重定向:重新指向其它path,会改变网址
    component: HomeView,
    meta:{
      title:"首页"
    },
    children: [
      {
        path: "/staff/userList",
        component: () => import("@/views/staff/staff.vue"),
        meta: { title: "员工列表" },
      },
      {
        path: "/daka/clockList",
        component: () => import("../views/daka/daka.vue"),
        meta: { title: "打卡列表" },
      },
      {
        path: "/device/deviceList",
        component: () => import("../views/device/device.vue"),
        meta: { title: "设备列表" },
      },
      {
        path: "/account/accountList",
        component: () => import("../views/account/account.vue"),
        meta: { title: "账号管理" },
      },
      {
        path: "/account/access",
        component: () => import("../views/account/access.vue"),
        meta: { title: "角色管理" },
      },
      {
        path: "/detail",
        name: "detail",
        component: () => import("../views/detail.vue"),
      },
    ],
  },
  //登录页
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login.vue"),
    meta: {
      title: "登录页",
    },
  },
  //404页面
  {
    path: "/:pathMatch(.*)",
    component: () => import("@/views/404.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem("token");
  if (token && to.path == "/login") {
    next("/");
  } else if (!token && to.path != "/login") {
    next("/login");
  } else {
    next();
  }
});

export default router;
