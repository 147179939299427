import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import { Login } from "../api/http";

export default new Vuex.Store({
  state: {
    token: "",
  },
  getters: {},
  mutations: {
    SetToken(state, token) {
      state.token = token || "";
      localStorage.setItem("token", state.token);
    },
  },
  actions: {
    //登录
    GetToken({ commit }, form) {
      return new Promise((resolve, reject) => {
        Login(form)
          .then((res) => {
            console.log(res);
            commit("SetToken", res.data.token);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    //退出登录
    OutLogin({commit}){
      localStorage.removeItem("token")
    }
  },
  modules: {},
});
